<template>
  <div>
    <div v-if="isLogin" class="currentPlanTabs">
      <div
        :class="
          `${isPro ? 'hiddenFreePlan' : 'currentFreePlan'} ${
            isPro ? '' : 'displayPlan'
          }`
        "
      >
        <template v-if="!isPro">
          <i class="fas fa-check" />
          <strong>{{ $t("plans.currentPlan") }}</strong>
        </template>
      </div>
      <div :class="`currentProPlan ${isPro ? 'displayPlan' : ''}`">
        <template v-if="isPro">
          <i class="fas fa-check" />
          <strong>{{ $t("plans.currentPlan") }}</strong>
          <br />
          <span class="timedLimited">
            <span class="text-success"> <i class="fas fa-crown"></i> Pro </span>
            <router-link
              :to="{
                name: 'Profile',
                query: {
                  tab: 'plans'
                }
              }"
            >
              {{ `${$t("plans.planDuration")}${proTimeLimited} (GMT+8)` }}
            </router-link>
          </span>
        </template>
      </div>
    </div>
    <div class="availablePlan subscribe">
      <div class="freePlan">
        <h3>{{ $t("plans.free") }}</h3>
        <div class="duration">
          {{ $t("plans.lifetime") }}
        </div>
        <small class="text-info">
          &nbsp;
        </small>
        <div class="tCoin">
          <strong class="discountTCoin">
            $0 USD
          </strong>
        </div>
        <div class="discount"></div>
        <div class="apply">
          <UpgradeButton plansType="free" :isLogin="isLogin" />
        </div>
        <div class="featuresAvailable">
          <div v-for="i in 3" :key="i">
            <span class="available">
              <i class="fas fa-check" />
            </span>
          </div>
          <div>
            {{ $t("plans.twoFullTestsAndFivePractices") }}
          </div>
          <div v-for="i in 7" :key="i">
            <i class="fas fa-times" />
          </div>
        </div>
        <div class="bottomApply">
          <UpgradeButton plansType="free" :isLogin="isLogin" />
        </div>
      </div>
      <div class="vipPlan">
        <h3>{{ $t("plans.vip") }}</h3>
        <div class="vipPlans">
          <div class="thirtyDay subscribe">
            <div class="duration">
              {{ $t("Pro.1 month") }}
            </div>
            <template v-if="isTWOrUS">
              <small class="text-info">
                {{ $t("Pro.Renew at") }}
                $30 USD
              </small>
              <div class="tCoin">
                <strong class="discountTCoin">
                  $30 USD
                </strong>
                <div class="originTCoin">
                  $60 USD
                  <div v-if="langIsEn" class="removeLine removeLineEn" />
                  <div v-else class="removeLine removeLineTw" />
                </div>
              </div>
            </template>
            <template v-else>
              <small class="text-info">
                {{ $t("Pro.Renew at") }}
                $25 USD
              </small>
              <div class="tCoin">
                <strong class="discountTCoin">
                  $25 USD
                </strong>
                <div class="originTCoin">
                  $40 USD
                  <div v-if="langIsEn" class="removeLine removeLineEn" />
                  <div v-else class="removeLine removeLineTw" />
                </div>
              </div>
            </template>
            <div class="apply" v-if="subscriptionsStatus">
              <el-button
                v-show="subscriptionsStatus.subscriptionMonth"
                type="text"
                round
                disabled
                style="color:white"
              >
                <i class="fa fa-check"></i>
                {{ $t("Pro.Subscribed") }}
              </el-button>
              <el-button
                v-show="!subscriptionsStatus.subscriptionMonth"
                type="success"
                class="subscribe-button"
                @click="getPro('month', 1, isTWOrUS)"
                round
              >
                {{ $t("Pro.Subscribe") }}
              </el-button>
            </div>
            <div class="featuresAvailable">
              <div v-for="i in 10" :key="i">
                <span class="available">
                  <i class="fas fa-check" />
                </span>
              </div>
              <div>
                {{ $t("plans.20Discount") }}
              </div>
            </div>
            <div class="bottomApply">
              <el-button
                v-show="subscriptionsStatus.subscriptionMonth"
                type="text"
                round
                disabled
                style="color:white"
              >
                <i class="fa fa-check"></i> {{ $t("Pro.Subscribed") }}
              </el-button>
              <el-button
                v-show="!subscriptionsStatus.subscriptionMonth"
                type="success"
                class="subscribe-button"
                @click="getPro('month', 1, isTWOrUS)"
                round
              >
                {{ $t("Pro.Subscribe") }}
              </el-button>
            </div>
          </div>
          <div class="lifetime subscribe">
            <div class="duration">
              {{ $t("Pro.3 months") }}
            </div>
            <template v-if="isTWOrUS">
              <small class="text-info">
                {{ $t("Pro.Renew at") }}
                $75 USD
              </small>
              <div class="tCoin">
                <strong class="discountTCoin">
                  $75 USD
                </strong>
                <div class="originTCoin">
                  $160 USD
                  <div v-if="langIsEn" class="removeLine removeLineEn" />
                  <div v-else class="removeLine removeLineTw" />
                </div>
              </div>
            </template>
            <template v-else>
              <small class="text-info"> {{ $t("Pro.Renew at") }} $50 USD </small>
              <div class="tCoin">
                <strong class="discountTCoin">
                  $50 USD
                </strong>
                <div class="originTCoin">
                  $120 USD
                  <div v-if="langIsEn" class="removeLine removeLineEn" />
                  <div v-else class="removeLine removeLineTw" />
                </div>
              </div>
            </template>
            <div class="apply">
              <el-button
                v-show="subscriptionsStatus.subscription3Months"
                type="text"
                round
                disabled
                style="color:white"
              >
                <i class="fa fa-check"></i> {{ $t("Pro.Subscribed") }}
              </el-button>
              <el-button
                v-show="!subscriptionsStatus.subscription3Months"
                type="success"
                class="subscribe-button"
                @click="getPro('month', 3,isTWOrUS)"
                round
              >
                {{ $t("Pro.Subscribe") }}
              </el-button>
            </div>
            <div class="featuresAvailable">
              <div v-for="i in 10" :key="i">
                <span class="available">
                  <i class="fas fa-check" />
                </span>
              </div>
              <div>
                {{ $t("plans.20Discount") }}
              </div>
            </div>
            <div class="bottomApply">
              <el-button
                v-show="subscriptionsStatus.subscription3Months"
                type="text"
                round
                disabled
                style="color:white"
              >
                <i class="fa fa-check"></i> {{ $t("Pro.Subscribed") }}
              </el-button>
              <el-button
                v-show="!subscriptionsStatus.subscription3Months"
                type="success"
                class="subscribe-button"
                @click="getPro('month', 3,isTWOrUS)"
                round
              >
                {{ $t("Pro.Subscribe") }}
              </el-button>
            </div>
          </div>
          <div class="lifetime subscribe">
            <div class="duration">
              {{ $t("Pro.6 months") }}
            </div>
            <template v-if="isTWOrUS">
              <small class="text-info">
                {{ $t("Pro.Renew at") }}
                $120 USD
              </small>
              <div class="tCoin">
                <strong class="discountTCoin">
                  $120 USD
                </strong>
                <div class="originTCoin">
                  $240 USD
                  <div v-if="langIsEn" class="removeLine removeLineEn" />
                  <div v-else class="removeLine removeLineTw" />
                </div>
              </div>
            </template>
            <template v-else>
              <small class="text-info"> {{ $t("Pro.Renew at") }} $80 USD </small>
              <div class="tCoin">
                <strong class="discountTCoin">
                  $80 USD
                </strong>
                <div class="originTCoin">
                  $240 USD
                  <div v-if="langIsEn" class="removeLine removeLineEn" />
                  <div v-else class="removeLine removeLineTw" />
                </div>
              </div>
            </template>
            <div class="apply">
              <el-button
                v-show="subscriptionsStatus.subscription6Months"
                type="text"
                round
                disabled
                style="color:white"
              >
                <i class="fa fa-check"></i> {{ $t("Pro.Subscribed") }}
              </el-button>
              <el-button
                v-show="!subscriptionsStatus.subscription6Months"
                type="success"
                class="subscribe-button"
                @click="getPro('month', 6, isTWOrUS)"
                round
              >
                {{ $t("Pro.Subscribe") }}
              </el-button>
            </div>
            <div class="featuresAvailable">
              <div v-for="i in 10" :key="i">
                <span class="available">
                  <i class="fas fa-check" />
                </span>
              </div>
              <div>
                {{ $t("plans.20Discount") }}
              </div>
            </div>
            <div class="bottomApply">
              <el-button
                v-show="subscriptionsStatus.subscription6Months"
                type="text"
                round
                disabled
                style="color:white"
              >
                <i class="fa fa-check"></i> {{ $t("Pro.Subscribed") }}
              </el-button>
              <el-button
                v-show="!subscriptionsStatus.subscription6Months"
                type="success"
                class="subscribe-button"
                @click="getPro('month', 6, isTWOrUS)"
                round
              >
                {{ $t("Pro.Subscribe") }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Decimal } from "decimal.js";
import UpgradeButton from "@/views/toefl/plans/UpgradeButton.vue";
import Plans from "@/apis/plans";

export default {
  props: {
    isLogin: {
      type: Boolean,
      default: false
    },
    isPro: {
      type: Boolean,
      default: false
    },
    isTWOrUS: {
      type: Boolean,
      default: false
    },
    proTimeLimited: {
      type: String,
      default: ""
    },
    langIsEn: {
      type: Boolean,
      default: false
    },
    isTW: {
      type: Boolean,
      default: false
    },
    tCoins: {
      type: Array,
      default: () => []
    },
    subscriptionsStatus: {
      type: Object,
      default: () => null
    }
  },
  components: {
    UpgradeButton
  },
  data() {
    return {};
  },
  computed: {
    offersTime() {
      return "";
    }
  },
  async mounted() {},

  methods: {
    async getPro(interval, intervalCount, isTWOrUS) {
      const loading = this.$loading({
        lock: true,
        text: "Redirecting to payment, please wait.",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      const res = await Plans.subscribePro({
        interval: interval,
        interval_count: intervalCount,
        price_level: isTWOrUS ? 1 : 0,
        success_url: "https://testadmit.com/toefl/profile?tab=plans",
        cancel_url: "https://testadmit.com/toefl/profile?tab=plans"
      });
      window.location.href = res.url;
      // loading.close();
    },
    showUSD(price) {
      if (Number(price) % 1 === 0) {
        return Number(price);
      } else {
        return price;
      }
    },
    getMinComboPrice(type, currency, demandTCoins) {
      const isTW = currency === "TW";
      const fromBigToSmallPackages = [...this.tCoins].sort(
        (a, b) => b.point - a.point
      );
      let result = 0;
      let workDemandTCoins = Number(demandTCoins);
      while (workDemandTCoins > 0) {
        const targetPackage = fromBigToSmallPackages.find(
          tCoin => tCoin.point <= demandTCoins
        );
        if (type === "discount") {
          if (isTW) {
            result = Decimal.add(result, this.showUSD(targetPackage.price));
          } else {
            result = this.showUSD(targetPackage.stripe_usd_price);
          }
        } else if (type === "original") {
          result = this.showUSD(targetPackage.original_price);
        }
        workDemandTCoins = Decimal.sub(workDemandTCoins, targetPackage.point);
      }
      return result;
    },
    getMinComboPriceWithUnit(type, currency, demandTCoins) {
      const isTW = currency === "TW";
      const result = this.getMinComboPrice(type, currency, demandTCoins);

      return `${isTW ? "" : ""}$${result} ${isTW ? "TWD" : "USD"}`;
    },
    getSaveUSD(months, tCoins) {
      const monthUSD = 18.88;
      const discountUSD = this.getMinComboPrice("discount", "US", tCoins);
      return Decimal.sub(Decimal.mul(monthUSD, months), discountUSD);
    }
  }
};
</script>

<style scoped lang="scss">
.upgradeButton {
  width: 200px;
  background: var(--themeColor);
  color: #ffffff;
}

.currentPlanTabs {
  display: flex;

  .displayPlan {
    padding: 12px 0px;
    background: #fff;
    border-bottom: 1px solid #ddd;
    box-shadow: 1px 1px 16px 4px #cccccc;
    position: relative;
  }

  .displayPlan::after {
    content: "";
    position: absolute;
    height: 17px;
    bottom: -18px;
    left: 0px;
    width: calc(100%);
    background: #fff;
  }

  strong {
    margin-left: 4px;
  }

  .timedLimited {
    color: #00000077;
  }

  .currentFreePlan {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 73px;
    width: calc(100% / 4 - 2px);
    text-align: center;
  }

  .hiddenFreePlan {
    width: calc(100% / 4);
    text-align: center;
  }

  .currentProPlan {
    height: 73px;
    width: calc(100% / 4 * 3);
    text-align: center;
  }
}
.subscribe-button {
  width: 120px;
  background-color: #409eff;
  border-color: #409eff;
}
.availablePlan {
  display: flex;
  box-shadow: 1px 1px 16px 4px #cccccc;
  background: #ffffff;
  .freePlan {
    border-right: 1px solid #ddd;
    width: calc(100% / 4);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 0px;

    h3 {
      color: var(--themeColor);
    }
    .duration {
      font-size: 1.2rem;
      width: 100%;
      margin-top: 20px;
      font-weight: bolder;
      text-align: center;
    }
    .tCoin {
      height: 66px;
      display: flex;
      align-items: center;
      white-space: nowrap;

      .discountTCoin {
        font-size: 2rem;
        color: var(--themeColor);
      }
    }
    .discount {
      width: 100%;
    }
    .apply {
      margin-top: 1rem;
      margin-bottom: 1rem;
      height: 40px;
    }
    .bottomApply {
      margin: 28px 0px 28px 0px;
    }
    .featuresAvailable {
      border-top: 1px solid #ddd;
      display: grid;
      grid-gap: 4px;
      grid-template-rows: repeat(11, 50px);
      padding: 0px 12px;
      width: 100%;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        .available {
          color: red;
        }
      }
    }
  }
  .vipPlan {
    padding-top: 12px;
    width: calc(100% / 4 * 3);
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      color: var(--themeColor);
    }
    .vipPlans {
      width: 100%;
      display: flex;
      align-items: center;

      .lifetime {
        border-left: 1px solid #ddd;
      }

      div {
        width: calc(100% / 2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 4px 0px;
        .duration {
          font-size: 1.2rem;
          width: 100%;
          margin-top: 20px;
          font-weight: bolder;
        }
        .tCoin {
          height: 66px;
          white-space: nowrap;
          .originalTCoin {
            font-size: 24px;
            color: #aaaaaa;
            position: relative;
            margin-bottom: -16px;
          }

          .originalTCoin::after {
            content: "";
            width: 92px;
            border-top: 2px solid #aaaaaa;
            position: absolute;
            left: 0px;
            top: 16px;
            transform: rotate(-6deg);
          }

          .discountTCoin {
            font-size: 2rem;
            color: var(--themeColor);
          }

          .originTCoin {
            font-size: 16px;
            color: #aaaaaa;
            position: relative;
            line-height: 0.8;
            font-weight: bold;

            .removeLine {
              border-top: 2px solid #aaaaaa;
              position: absolute;
              top: 8px;
              transform: rotate(-6deg);
            }

            .removeLineEn {
              width: 80px;
            }

            .removeLineTw {
              width: 80px;
              transform: rotate(-10deg);
            }
          }
        }
        .discount {
          margin-top: 10px;
          height: 80px;
          width: 100%;
          text-align: center;
          font-size: 24px;
          font-weight: bold;
          white-space: nowrap;

          .discountPrice {
            flex-direction: row;
          }

          .offers {
            font-size: 16px;
            color: red;
          }
        }
        .apply {
          margin-top: 1rem;
          margin-bottom: 1rem;
          height: 40px;
        }
        .bottomApply {
          margin: 28px 0px 28px 0px;
        }
        .featuresAvailable {
          border-top: 1px solid #ddd;
          width: 100%;
          display: grid;
          grid-gap: 4px;
          grid-template-rows: repeat(11, 50px);
          padding: 0px 12px;
          div {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            .available {
              color: red;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .availablePlan {
    .vipPlan {
      .vipPlans {
        div {
          .discount {
            font-size: 24px;
            .originPrice {
              .removeLine {
                top: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .availablePlan {
    .freePlan {
      .discount {
        height: 80px;
      }
    }

    .vipPlan {
      .vipPlans {
        div {
          .discount {
            margin-top: 0px;
            font-size: 18px;
            .originPrice {
              .removeLine {
                width: 120px;
                top: 8px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1220px) {
  .currentPlanTabs {
    display: none;
  }

  .availablePlan {
    display: none;
  }
}
.subscribe .discountTCoin {
  color: #409eff !important;
}
</style>
